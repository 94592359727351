import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Heading, Spinner } from '@ensdomains/thorin'

const Container = styled.div(
  ({ theme }) => css`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-gap: ${theme.space['4']};
    gap: ${theme.space['4']};
    align-items: center;
  `,
)

export const LoadingOverlay = ({ sx }: { sx?: React.CSSProperties }) => {
  const { t } = useTranslation('common')

  return (
    <Container
      style={{
        marginTop: '30%',
        ...sx,
      }}
    >
      <Heading level="1">{t('loading')}</Heading>
      <Spinner size="large" color="accent" />
    </Container>
  )
}
